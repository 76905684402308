import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  muiProKey,
  localizeKey,
  sentryDSNKey,
  amplitudeProdKey,
  procoreApiUrlProduction,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "staging",
  appRegion: "eu",
  appEnv: "eu-production",
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.eu",
    sphereViewerUrl: "https://viewer.holobuilder.eu",
    dashboard20Api: "https://sphere.holobuilder.eu/api",
    hbWebEditorUrl: "https://app.holobuilder.eu",
    progressApiUrl: "https://progress.api.holobuilder.eu",
    sphereEntryPageUrl: "https://entry.holobuilder.eu",
    // TODO: Use the correct URL once we are ready to release
    cookieManagerUrl: undefined,
    powerBIBaseUrl: "https://hb-powerbi-func-eu.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.holobuilder.eu",
    hbDashboardUrl: "https://dashboard.holobuilder.eu",
    registrationApiUrl: "https://cloud-reg.api.holobuilder.eu",
    stagingAreaApiUrl: "https://staging-area.api.holobuilder.eu",
    photogrammetryApiUrl: "https://hb-pre-alignment-batch-eu.azurewebsites.net",
    uploadFrontDoorUrl: "https://files.holobuilder.eu",
    uploadStorageUrl: "https://holobuildereucorestorage.blob.core.windows.net",
    activityApiUrl: "https://activity.api.holobuilder.eu",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: undefined,
    auth0: {
      domain: "login.eu.farosphere.com",
      clientId: "VIgbt2dDKTFhsj2as6umqhKylJ01psTg",
    },
    amplitudeKey: amplitudeProdKey,
  },
  integrations: {
    procoreApiUrl: procoreApiUrlProduction,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};
